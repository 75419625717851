<template>
    <modal ref="modalCambiarDescuento" titulo="Cambiar el beneficio de descuento masivamente" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="guardar">
        <ValidationObserver ref="validacion">
            <cargando v-if="cargando" />
            <section class="mx-4">
                <div class="row mx-0 mt-2">
                    <div class="col-12">
                        <p class="text-general f-600">
                            Los {{ $config.vendedor }} del cedis pueden acogerse al beneficio de descuento de lista.
                        </p>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col">
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera automatica <i class="text-general2">({{ contadores.vendedoresAuto }} {{ $config.vendedor }} )</i>
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="2" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera manual <i class="text-general2">({{ contadores.vendedoresManual }} {{ $config.vendedor }} )</i>
                                </span>
                            </div>
                        </div>

                        <div v-if="model.descuentoLeecheros === 2" class="row mx-5 mt-2">
                            <div class="col-auto">
                                <p class="f-12 text-general ml-2">Clasificación</p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Clasificación">
                                    <el-select v-model="model.categoriaLista" size="small" placeholder="Seleccionar">
                                        <el-option
                                        v-for="item in listaClasificacion"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <div>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    No acogerlos al beneficio <i class="text-general2">({{ contadores.vendedoresNoAplica }} {{ $config.vendedor }} )</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="text-general f-600">
                            Los clientes de los {{ $config.vendedor }} del cedis pueden acogerse al beneficio de descuento de lista
                        </p>
                    </div>
                </div>
                <!-- <div class="row mx-2 mt-3">
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoClientes" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    Si <i class="text-general2">({{ contadores.clientesAplica }} {{ $config.vendedor }} )</i>
                                </span>
                            </div>
                        </div>
                        <div class="row mx-0 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoClientes" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    No <i class="text-general2">({{ contadores.clientesNoAplica }} {{ $config.vendedor }} )</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row mx-0">
                    <div class="col">
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoClientes" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera automatica <i class="text-general2">({{ contadoresCliente.clientesAuto }} Clientes )</i>
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoClientes" :value="2" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera manual <i class="text-general2">({{ contadoresCliente.clientesManual }} Clientes )</i>
                                </span>
                            </div>
                        </div>

                        <div v-if="model.descuentoClientes === 2" class="row mx-5 mt-2">
                            <div class="col-auto">
                                <p class="f-12 text-general ml-2">Clasificación</p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Clasificación cliente">
                                    <el-select v-model="model.categoriaListaClientes" size="small" placeholder="Seleccionar">
                                        <el-option
                                        v-for="item in listaClasificacionCliente"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <div>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoClientes" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    No acogerlos al beneficio <i class="text-general2">({{ contadoresCliente.clientesNoAplica }} Clientes )</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="text-general f-600">
                            Aplica
                        </p>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col">
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.aplicaTodos" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    Aplicar solo a nuevos Vendedores y Clientes
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.aplicaTodos" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    Aplicar a Vendedores y clientes actuales y nuevos registrados
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from "~/services/alianzas_leecheros";

export default {
    data(){
        return{
            cargando: false,
            listaClasificacion: [],
            listaClasificacionCliente: [],
            model: {
                idCedis: this.$route.params.id_cedis,
                descuentoLeecheros: 0,
                descuentoClientes: 0,
                categoriaLista: null,
                aplicaTodos:0,
                descuentoClientes:0,
                categoriaListaClientes: null,

            },
            contadores:{},
            contadoresCliente:{}
        }
    },

    methods: {
        async toggle(){
            this.$refs.validacion.reset();
            await this.getData();
            this.$refs.modalCambiarDescuento.toggle();
        },

        async getData(){
            try {
                const {data} = await Service.getParametrosCedis(this.model.idCedis);
                this.listaClasificacion = data.clasificaciones;
                this.listaClasificacionCliente = data.clasificacionesCliente;
                this.model.descuentoLeecheros = data.data.descuento_lista;
                this.model.descuentoClientes = data.data.descuento_lista_clientes;
                this.model.categoriaLista = data.data.id_categoria_lista;

                this.contadores = data.contadores
                this.contadoresCliente = data.contadoresCliente

            } catch(e){
                this.error_catch(e);
            }
        },

        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                this.cargando = true;
                if(parseInt(this.model.descuentoLeecheros) !== 2){
                    this.model.categoriaLista = null;
                }

                if(parseInt(this.model.descuentoClientes) !== 2){
                    this.model.categoriaListaClientes = null;
                }
                const {data} = await Service.postDescuentoTienda(this.model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('update');
                this.$refs.modalCambiarDescuento.toggle();

            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        },
    },
}
</script>
<style lang="scss" scoped>

</style>
